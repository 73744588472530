import axios from "axios";
import qs from "querystring";
// eslint-disable-next-line
var config = {};
// eslint-disable-next-line
var s_token = "";
// eslint-disable-next-line
var projectId = "";
// eslint-disable-next-line
var portalId = "";
// eslint-disable-next-line
var baas = axios.create(config);
// eslint-disable-next-line
var fileServer = axios.create(config);

const request = function () {
};
request.prototype = {
    init: function (options) {
        config = {
            timeout: 30000,
            withCredentials: true, // 访问线上api时axios发送跨域请求时需要设置这个参数
            baseURL: options.baseUrl,
        };
        s_token = options.s_token;
        projectId = options.projectId;
        portalId = options.portalId;
        baas = axios.create(config);
        fileServer = axios.create(config);
        baas.interceptors.request.use(function (config) {
            config.headers["Authorization"] = s_token;
            config.headers["projectId"] = projectId;
            config.headers["portalId"] = portalId;
            config.headers["curSystem"] = 'healthcare';
            return config;
        });
        fileServer.interceptors.request.use(function (config) {
            config.headers["Authorization"] = s_token;
            config.headers["projectId"] = projectId;
            config.headers["portalId"] = portalId;
            config.headers["curSystem"] = 'healthcare';
            config.headers["Content-Type"] = "multipart/form-data";
            return config;
        });
        //添加响应拦截器
        baas.interceptors.response.use(function (response) {
            if (response && response.headers && response.headers.token) {
                //刷新token
                options.vuex("s_token", response.headers.token);
            }
            if (response && response.data && response.data.code === 401) {
                localStorage.clear();
                location.reload();
                throw new Error("logout");
            }
            return response;
        });
        fileServer.interceptors.response.use(function (response) {
            if (response && response.headers && response.headers.token) {
                //刷新token
                options.vuex("s_token", response.headers.token);
            }
            if (response && response.data && response.data.code === 401) {
                localStorage.clear();
                location.reload();
                throw new Error("logout");
            }
            return response;
        });
    },
};

const post = function (options) {
    return new window.Promise((resolve) => {
        baas
            .post(options.url, options.params || {})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
            });
    });
};
const put = function (options) {
    return new window.Promise((resolve) => {
        baas
            .put(options.url, options.params || {})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
            });
    });
};
const del = function (options) {
    return new window.Promise((resolve) => {
        baas
            .delete(options.url, {data: options.params || {}})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
            });
    });
};
const get = function (options) {
    return new window.Promise((resolve) => {
        let data = options.params
            ? options.url + "?" + qs.stringify(options.params)
            : options.url;
        baas
            .get(data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
            });
    });
};
const uploadFile = function (options) {
    return new window.Promise((resolve) => {
        fileServer
            .post(options.url, options.params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
            });
    });
};

const downloadFile = function (option) {
    return new window.Promise((resolve) => {
        baas
            .get(option.url, {
                params: {},
                responseType: "blob",
            })
            .then((response) => {
                resolve(response);
                if (!response) {
                    return;
                }
                let blobpart = new Blob([response.data]);
                let url = window.URL.createObjectURL(blobpart);
                let link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                link.setAttribute(
                    "download",
                    decodeURIComponent(response.headers["content-disposition"]).replace(
                        new RegExp("attachment;filename="),
                        ""
                    )
                );
                document.body.appendChild(link);
                link.click();
                //释放URL对象所占资源
                window.URL.revokeObjectURL(url);
                //用完即删
                document.body.removeChild(link);
            })
            .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
            });
    });
};

const server = {};
server.install = function (Vue) {
    // 4. 添加实例方法
    Vue.prototype.$request = request;
    Vue.prototype.$post = post;
    Vue.prototype.$put = put;
    Vue.prototype.$get = get;
    Vue.prototype.$del = del;
    Vue.prototype.$uploadFile = uploadFile;
    Vue.prototype.$downloadFile = downloadFile;
};
export default {
    server,
    request,
    post,
    put,
    get,
    del,
    uploadFile,
    downloadFile,
};
